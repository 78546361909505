import { BaseLayout, DefaultNavbar } from '@components/common'
import { Menu, Paiements, PracticalInformation } from '@components/sections/home'
import { Intro } from '@components/home/intro'
import { Reinsurance } from '@components/reinsurance'
import { HowItWorks } from '@components/home/how-it-works'
import { withLayout, withProps } from '@utils/hoc'
import { Cover } from '@components/ui'
import { PageComponent } from 'types/app'
import intro from '@assets/images/img-intro.jpg'

const Home: PageComponent = () => {
  return (
    <>
      <Cover className="relative bottom-[100px] top-[-110px] z-0 h-[500px] sm:mb-3 lg:top-[-220px] lg:h-[720px]" src={intro.src}>
        <div className="container mx-auto mt-[110px] h-full px-[30px]">
          <Intro />
          <div className="flex justify-center">
            <Reinsurance classes="absolute top-[350px] md:top-[400px] lg:top-[650px]  bg-white rounded xl:py-[40px] xl:px-[40px] py-[20px] px-[20px] mx-[5px] xl:bottom-[-25px]" />
          </div>
        </div>
      </Cover>
      <div className="lg:mt-[-110px]">
        <HowItWorks />
        <Menu />
        <Paiements />
        <PracticalInformation />
      </div>
    </>
  )
}

const Layout = withProps(BaseLayout, { navbar: <DefaultNavbar /> })

export default withLayout(Home, Layout)
