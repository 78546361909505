import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import Card from './Card'
import plat from '@assets/images/img-plat.png'
import platWebP from '@assets/images/img-plat.webp'
import cook from '@assets/images/img-cook.png'
import cookWebP from '@assets/images/img-cook.webp'
import gamelle from '@assets/images/img-gamelle.png'
import gamelleWebP from '@assets/images/img-gamelle.webp'
import themeColor from '@const/colors.json'
import { withStyles } from 'tss-react/mui'

const Background = withStyles(Box, {
  root: {
    background: themeColor.blue7
  }
})

const HowItWorks: FC = () => {
  const explanations = [
    {
      image: plat,
      imageWebP: platWebP,
      title: 'Choisissez vos plats',
      description: 'variés & équilibrés'
    },
    {
      image: cook,
      imageWebP: cookWebP,
      title: 'On vous prépare tout',
      description: 'vous aurez juste à réchauffer'
    },
    {
      image: gamelle,
      imageWebP: gamelleWebP,
      title: 'Recevez vos gamelles',
      description: 'au travail comme à la maison '
    }
  ]

  return (
    <Background className="container mx-auto mb-[100px] mobile:mb-[50px] mobile:mt-[50px]">
      <div className="flex h-full flex-col items-center justify-evenly gap-y-[50px] py-[30px]">
        <Typography variant="h2" component="h2" className="text-center">
          Comment ça marche ?
        </Typography>
        <div className="grid grid-cols-1 gap-12 sm:grid-cols-3">
          {explanations.map(item => (
            <Card key={item.title} image={item.image} imageWebP={item.imageWebP} title={item.title} description={item.description} />
          ))}
        </div>
      </div>
    </Background>
  )
}

export default HowItWorks
