import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'

const Title = withStyles({
  root: {
    fontWeight: 'bold',
    textAlign: 'center'
  }
})(Typography)

const Description = withStyles({
  root: {
    fontWeight: 600,
    textAlign: 'center'
  }
})(Typography)

interface Props {
  image: any
  imageWebP: any
  title: string
  description: string
  height?: string
  width?: string
}

const Card: FC<Props> = ({ image, imageWebP, title, description }) => {
  return (
    <>
      <div className="align-center flex flex-col justify-between">
        <div className="mx-auto max-h-[170px] max-w-[170px]">
          <picture>
            <source srcSet={imageWebP.src} type="image/webp" />
            <img src={image.src} width={170} height={170} alt="card image" className="h-[200px] max-w-[170px]" />
          </picture>
        </div>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </div>
    </>
  )
}

export default Card
