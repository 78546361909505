import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { Button, CTA, currency } from '@components/ui'
import CartIcon from '@assets/images/svg/ico-remplir-panier.svg'
import routes from '@const/routes'
import colors from '@const/colors.json'
import { withProps } from '@utils/hoc'
import { withStyles } from '@mui/styles'

const P = withStyles({
  root: {
    fontWeight: 800
  }
})(Typography)

interface Props {}

const Intro: FC<Props> = () => {
  return (
    <div className="z-10 mx-auto flex h-[284px] items-center justify-start pt-[50px] md:pt-[100px] md:pt-[260px] lg:mx-[105px] lg:w-[688px] lg:pt-[260px]">
      <div className="flex flex-col items-start justify-between">
        <Typography variant="h1" className="mb-[20px] lg:mb-[30px]" component="h1">
          <p className="text-left text-2xl sm:text-5xl mobile:text-center">Des gamelles pour manger sans se prendre la tête</p>
        </Typography>
        <P className="mb-[30px]  text-left sm:w-[60%] mobile:text-center">
          <span className="text-base">
            Des recettes simples et délicieuses, des ingrédients frais et de qualité, <span className="whitespace-nowrap">à partir de 1080 {currency}</span>
          </span>
        </P>
        <div className="flex flex-wrap items-center justify-start gap-[30px] text-center mobile:mx-auto">
          <CTA
            wrapper={withProps(Button, {
              variant: 'contained',
              color: 'primary',
              className: 'h-125 w-full lg:w-auto'
            })}
            url={routes.menus.uri}
            label="Je remplis mon panier"
            icon={<CartIcon />}
            hover={colors.white}
            reverse
          />
        </div>
      </div>
    </div>
  )
}

export default Intro
